import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'

// Reverb
import Text from 'volcano/components/Text'


export const relatedEntitySelector = (state, modelList, columnPath, firstId) => {
  const columnPathSplit = columnPath.split('.')
  let targetEntity
  let targetId = firstId
  let targetFieldName
  let attr

  modelList.forEach((modelData, dx) => {
    targetEntity = state.entity[changeCase.camel(modelData.name)].entity[targetId]
    if (dx < modelList.length - 1) {
      targetId = targetEntity[columnPathSplit[dx+1]]
    }
  })

  return targetEntity
}

export const getTargetModelData = (modelDataMap, modelName, targetPath, fieldName) => {
  const modelData = modelDataMap[changeCase.pascal(modelName)]

  let result = []

  let targetAttrName, nextTargetPath, nextFieldName
  if (targetPath.includes('.')) {
    const targetPathSplit = targetPath.split('.')
    targetAttrName = targetPathSplit[0]
    nextFieldName = targetPathSplit[1] || fieldName
    nextTargetPath = targetPathSplit.slice(1).join('.')
  } else {
    targetAttrName = targetPath
    nextFieldName = fieldName
  }
  const targetFieldData = modelData.fields[targetAttrName]
  if (!targetFieldData) {
    console.log(modelData, targetAttrName)
    throw Error('TARGET_FIELD_DATA_NOT_FOUND')
  }

  if (!targetFieldData.is_relationship) result.push({modelData, targetFieldData, fieldName, targetPath})
  else if (targetFieldData.is_relationship && nextTargetPath) result.push({modelData, fieldName})

  if (targetFieldData.is_relationship) result = result.concat(getTargetModelData(modelDataMap, targetFieldData.target_model_name, nextTargetPath || targetFieldData.identifier, nextFieldName))

  return result
}

export const getRenderDataForField = (modelDataMap, fieldData, modelName, attrPath) => {
  const modelList = []
  let targetFieldData
  let columnPath
  let searchPath

  if (fieldData.is_relationship) {
    const targetModelData = getTargetModelData(modelDataMap, modelName, attrPath, fieldData.name)
    targetModelData.forEach(targetItem => {
      modelList.push({...targetItem.modelData, fieldName: targetItem.fieldName, targetPath: targetItem.targetPath})
      targetFieldData = targetItem.targetFieldData
    })

    columnPath = `${modelList.map((modelData, dx) => {
      let name
      if (dx === modelList.length - 1 && modelList.length > 1) name = modelData.name
      else name = modelData.fieldName
      return changeCase.snake(name)
    }).join('.')}${modelList.length ? '.' : ''}${modelList[modelList.length - 1].targetPath}`

    searchPath = columnPath
  } else {
    targetFieldData = fieldData
    columnPath = attrPath
    if (targetFieldData.model_attr_kind == 'JSON') searchPath = `${targetFieldData.name}.${attrPath}`
    else searchPath = columnPath
  }

  return [targetFieldData, columnPath, searchPath, modelList]
}

export const getLabelForField = (targetFieldData, columnPath, modelList, labelRenderer) => {
  let label
  let fieldNameIsObvious = false
  if (labelRenderer) label = labelRenderer(targetFieldData, columnPath)
  else if (modelList.length == 1) {
    label = <Text textCase='title' id={`field.${changeCase.snake(modelList[0].fieldName)}`}/>
    if (targetFieldData.name == 'name') fieldNameIsObvious = true
  } else {
    let colName
    if (targetFieldData.model_attr_kind == 'JSON') {
      const columnPathSplit = columnPath.split('.')
      colName = columnPathSplit[columnPathSplit.length - 1]
    } else colName = targetFieldData.label || targetFieldData.name

    if (colName == 'name') fieldNameIsObvious = true

    if (!fieldNameIsObvious || modelList.length == 0) label = <Text textCase='title' id={`field.${colName}`}/>
  }

  // let listToUse = modelList
  // if (fieldNameIsObvious) listToUse =

  let topLabel
  let preLabel
  if (modelList.length > 1) {
    const topLabelList = []
    const preLabelList = []

    modelList.forEach((modelData, dx) => {
      let name
      if (dx === modelList.length - 1 && modelList.length > 1) name = modelData.name
      else name = modelData.fieldName

      const text = <Text textCase='title' id={`field.${changeCase.snake(name)}`} key={dx}/>
      if (dx === modelList.length - 1) {
        preLabelList.push(text)
        if (label) preLabelList.push(' > ')
      } else {
        if (dx < modelList.length - 1) topLabelList.push(text)
        if (topLabelList.length > 1 && dx != modelList.length - 1) topLabelList.push(' > ')
      }
    })
    topLabel = topLabelList
    preLabel = preLabelList

    if (!label) {
      label = preLabel
      preLabel = null
    }
  } else if (modelList.length == 1 && !fieldNameIsObvious) {
    const text = <Text textCase='title' id={`field.${changeCase.snake(targetFieldData.name)}`}/>
    topLabel = label
    label = text
  }

  return [label, topLabel, preLabel]
}


export const getColumnsForModel = (fields, modelData, activeModelData, options) => {
  return fields.map(fieldName => {
    let modelName = modelData.name
    let fieldAttrPath = fieldName

    let columnFieldData
    let columnName
    let labelRenderer
    if (fieldName.includes('.')) {
      const fieldNameSplit = fieldName.split('.')
      fieldAttrPath = fieldNameSplit.slice(1).join('.')
      columnName = fieldNameSplit[fieldNameSplit.length-1]
      columnFieldData = modelData.fields[fieldNameSplit[0]]
      if (!columnFieldData) {
        console.log('FIELD_NOT_FOUND', fieldNameSplit[0])
      } else {
        if (columnFieldData.is_relationship) {
          modelName = changeCase.camel(columnFieldData.target_model_name)
        }
      }
    } else {
      if (modelData.fields[fieldName]) {
        columnFieldData = modelData.fields[fieldName]
        columnName = fieldName
      } else if (modelData.collections[fieldName]) {
        columnFieldData = modelData.collections[fieldName]
        columnName = fieldName
      } else {
        console.log('FIELD_NOT_FOUND', fieldName, modelData)
        columnFieldData = null
        columnName = fieldName
      }
    }

    // console.log('--', 'col', modelName, fieldAttrPath, columnFieldData)

    if (options && options.columnMap && options.columnMap[columnName]) {
      const colOpt = options.columnMap[columnName]
      if (colOpt.label) labelRenderer = () => colOpt.label
      if (colOpt.kind) {
        columnFieldData = {...columnFieldData}
        columnFieldData.model_attr_kind = colOpt.kind
      }
    }

    return {
      modelName,
      identifier: modelData.identifier,
      fieldAttrPath,
      columnFieldData,
      columnName,
      fieldName,
      activeModelData,
      detailOnClick: options && options.detailOnClick,

      Header: columnName,
      accessor: fieldAttrPath,
      labelRenderer
    }
  })
}

export const getColumnPropsFromListView = (listView, modelData, options, activeModelData) => {
  if (!listView.view) return []
  const columns = getColumnsForModel(listView.view.fields, modelData, activeModelData, options)
  const { hasId, hasSelect, hasMenu } = listView.view.options
  const { hasMenu: optHasMenu, hasId: optHasId, hasSelect: optHasSelect } = options

  // const renderSelect = ({fieldData, options}) => <SelectableIcon icon={options.icon} handleSelect={() => handleSelect(fieldData.data.id)} entityId={fieldData.data.id} entityKind={fieldData.data.modelName} left listViewName={listView.meta.name}/>

  // if (hasId && (optHasId != undefined ? optHasId : true)) columns.unshift({name: '__id', width: 'MINI', disableSort: true, flexGrow: 0, label: 'id', converter: idConverter})
  // if (hasSelect && (optHasSelect != undefined ? optHasSelect : true)) columns.unshift({name: '__icon', width: 'MINI', disableSort: true, flexGrow: 0, renderer: renderSelect, converter: selectConverter, options: {icon: modelData.icon}})
  // if (hasMenu && (optHasMenu != undefined ? optHasMenu : true)) columns.push({name: '__menu', renderer: renderMenu, width: 'MINI', disableSort: true, flexGrow: 0, new: true, label: 'menu', menu: true, converter: menuConverter})

  // if (hasId && (optHasId != undefined ? optHasId : true)) columns.unshift({name: '__id', colWidth: 'MINI', disableSort: true, flexGrow: 0, label: 'id', converter: idConverter})
  if (hasMenu && (optHasMenu != undefined ? optHasMenu : true)) columns.push({ kind: 'custom', name: 'menu', label: 'menu', colWidth: 'MINI', flexGrow: 1, modelData: modelData })

  return columns
}