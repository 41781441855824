import React from 'react';

// Vendor
import {
  Route,
  Routes,
  useMatch
} from 'runic-aura/router'

// Self
import AuthenticationLogin from './AuthenticationLogin'
import AuthenticationLogout from './AuthenticationLogout'
// import AuthenticationForgot from './AuthenticationForgot'
// import AuthenticationReset from './AuthenticationReset'
// import AuthenticationEmailVerify from './AuthenticationEmailVerify'


const AuthenticationBase = props => {

  return(
    <Routes>
      <Route path={`login`} element={<AuthenticationLogin/>}/>
      <Route path={`login/:rcTenantCode`} element={<AuthenticationLogin/>}/>
      <Route path={`logout`} element={<AuthenticationLogout/>}/>
      {/* <Route exact path={`${match.url}/login/:rcTenantId`} component={AuthenticationLogin}/>
      <Route exact path={`${match.url}/forgot`} component={AuthenticationForgot}/>
      <Route exact path={`${match.url}/reset/:token`} component={AuthenticationReset}/>
      <Route exact path={`${match.url}/verify/:token`} component={AuthenticationEmailVerify}/> */}
    </Routes>
  )
}

export default AuthenticationBase
