import React from 'react'

// Runic
import { Route, Routes, useParams } from 'runic-aura/router'
import AuthenticationCheck from 'runic-aura/apps/authentication/components/AuthenticationCheck'

import RunicTenantSelector from './RunicTenantSelector'
import RunicTenantIndex from './RunicTenantIndex'


const RunicHome = props => {

  return (
    <AuthenticationCheck>
      <Routes>
        <Route path="/home" element={<RunicTenantSelector />}/>
        <Route path="/:rcTenantId/*" element={<RunicTenantIndex />}/>
      </Routes>
    </AuthenticationCheck>
  )
}

export default RunicHome
