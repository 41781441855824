import React from 'react'

// Volcano
import { styled, t, tt, tc, Box } from '../index'
import Icon from './Icon'


const RInputWrapper = styled.div`
  backgroundColor: ${tt('input.backgroundColor', 'colors.light8')};
  // border-bottom: 1px solid ${tt('input.baseBorderColor', 'colors.primary8')};

  &:hover {
    background: ${tt('input.backgroundColorHover', 'colors.light6')};
  }
  position: relative;

  border-radius: ${tt('input.borderRadius', 'radii.1')} ${tt('input.borderRadius', 'radii.1')} 0 0;

  transition: all linear 0.1s;

  &:before {
    content: '';

    border-left: 1px solid ${p => p.hasFocus ? tt('input.borderColorActive', 'colors.primary')(p) : tt('input.borderColorPassive', 'colors.primary9')(p)};

    position: absolute;
    top: ${p => p.isActive ? '5px' : '15px'};

    height: ${p => p.isActive ? '35px' : '14px'};
    width: 1px;

    transition: all linear 0.1s;
  }

  &:after {
    content: '';

    border-right: 1px solid ${p => p.hasFocus ? tt('input.borderColorActive', 'colors.primary')(p) : tt('input.borderColorPassive', 'colors.primary9')(p)};

    position: absolute;
    right: 0px;
    top: ${p => p.isActive ? '5px' : '15px'};

    height: ${p => p.isActive ? '35px' : '14px'};
    width: 1px;

    transition: all linear 0.1s;
  }

  cursor: text;
`

const RInputLabel = ({
  children,
  status,
  sx,
  activeSx
}) => (
  <Box status={status} sx={{
    color: 'dark7',
    position: 'absolute',
    top: '13px',
    left: '14px',
    transition: 'all linear 0.1s',
    zIndex: 1,
    cursor: 'text',
    ...sx
  }} variants={{
    _prop: 'status',
    active: {
      color: 'dark7',
      top: '-6px',
      left: '10px',
      fontSize: 's',
      background: '#FFF',
      py: '2px',
      px: '4px',
      fontWeight: 'bold',
      color: 'dark6',
      ...activeSx
    }
  }}>
    {children}
  </Box>
)

const RInput = styled.input`
  border: none;
  background: none;

  width: calc(100% - 20px);

  margin: 12px;

  ${p => p.isActive && 'margin-top: 16px; margin-bottom: 8px;'}

  font-family: ${t('fontFamily')};
  font-size:  ${t('fontSizes.base')};

  &:focus {
    outline: none;
  }

  transition: all linear 0.1s;

  z-index: 2;
`

const RTextAreaInput = styled.textarea`
  border: none;
  background: none;

  width: calc(100% - 20px);

  margin: 12px;

  ${p => p.isActive && 'margin-top: 16px; margin-bottom: 8px;'}

  font-family: ${t('fontFamily')};
  font-size:  ${t('fontSizes.base')};

  &:focus {
    outline: none;
  }

  transition: all linear 0.1s;

  z-index: 2;

  max-width: 100%;
`

const After = ({
  icon,
  items
}) => (
  <Box sx={{
    position: 'absolute',
    right: 1,
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }}>
    <Icon icon={icon}/>
    {items && items.map((item, dx) => (
      <Box key={dx}>
        {item}
      </Box>
    ))}
  </Box>
)

const TextField = (props, ref) => {
  const { placeholder, label, helperText, onChange, value, fullWidth, className, tabIndex, multiline, focus, selectOnMount, onMount, hasDefaultValue, afterIcon, afterItems, onBlur, onFocus, requiredLabel = true, wrapperSx, labelSx, activeLabelSx, ...rest } = props
  const inputRef = React.useRef(null)
  const finalRef = ref || props.refs ? e => {
    ref && ref(e)
    if (props.refs) props.refs.forEach(propRef => propRef.current = e)
    inputRef.current = e
  } : inputRef
  const [hasFocus, setHasFocus] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
    select: () => inputRef.current.select(),
    name: props.name
  }))

  React.useEffect(() => {
    if (focus && selectOnMount) inputRef.current.select()
    if (focus) inputRef.current.focus()
  }, [])

  const handleFocus = e => {
    e.stopPropagation()
    setHasFocus(true)
    inputRef.current && inputRef.current.focus()
    onFocus && onFocus(e)
  }

  const handleBlur = e => {
    setHasFocus(false)
    inputRef.current && inputRef.current.blur()
    onBlur && onBlur(e)
  }

  const isActive = (inputRef.current && inputRef.current.value) || hasFocus || props.value

  const C = multiline || props.type === 'textarea' ? RTextAreaInput : RInput

  return (
    <Box as={RInputWrapper} isActive={isActive} hasFocus={hasFocus} className={className} onClick={handleFocus} sx={wrapperSx}>
      <RInputLabel status={isActive && 'active'} sx={labelSx} activeSx={activeLabelSx}>{label} {requiredLabel && (rest.required ? '*' : '(opsiyonel)')}</RInputLabel>
      <C isActive={isActive} onChange={onChange} value={value} onFocus={handleFocus} onBlur={handleBlur} ref={finalRef} tabIndex={tabIndex} {...rest}/>
      {(afterIcon || afterItems) && <After icon={afterIcon} items={afterItems}/>}
    </Box>
  )

}

export default React.forwardRef(TextField)
