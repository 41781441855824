import React from 'react'
import ReactDOM from 'react-dom'

// Volcano
import usePopper from 'volcano/hooks/usePopper'

import useOnClickOutside from 'volcano/hooks/useOnClickOutside'
import useEventListener from 'volcano/hooks/useEventListener'
import keycode from 'keycode'
import { Box } from 'volcano'

const useModal = ({ placement, defaultIsOpen, ...rest }) => {
  const referenceNode = process.browser && document.body
  const popperNode = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen)

  const { style, update } = usePopper({
    referenceNode, popperNode, isOpen, placement, modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          // fallbackPlacements: [ 'left', 'right', 'top', 'bottom'],
          // rootBoundary: 'document',
        }
      },

      {
        name: 'preventOverflow',
        options: {
          // mainAxis: false, // true by default
        },
      },
    ]
  })

  const toggleIsOpen = React.useCallback(() => {
    setIsOpen((curr) => !curr)
    update()
    setTimeout(() => update && update(), 100)
  }, [update])

  React.useEffect(() => {
    update()
  }, [isOpen])

  const close = React.useCallback(() => setIsOpen(false), [])
  const open = React.useCallback(() => setIsOpen(true), [])
  const handleKeyPress = React.useCallback((e) => e.keyCode === keycode('Esc') && close(), [])

  useOnClickOutside(popperNode, close, true)
  useEventListener('keydown', handleKeyPress)

  const menuProps = {
    ref: popperNode,
    style: {
      ...style,
      zIndex: 9999
    },
  }


  return [menuProps, isOpen, open, close]
}

import { ModalContext } from '../hooks/useModal'

const ModalContent = props => {
  const modalRoot = document.getElementById('modal')
  const parent = React.useContext(ModalContext)
  const [elem] = React.useState(() => document.createElement('div'))
  React.useEffect(() => {
    if (parent) parent.appendChild(elem)
    else modalRoot.appendChild(elem)

    // document.body.style.overflow = 'hidden'

    return () => {
      if (parent) parent.removeChild(elem)
      else modalRoot.removeChild(elem)

      // document.body.style.overflow = 'unset'
    }
  }, [])
  const content = (
    <ModalContext.Provider value={elem}>
      {props.children}
    </ModalContext.Provider>
  )
  return ReactDOM.createPortal(
    content,
    elem,
  )
}

const Modal = React.forwardRef((props, ref) => {
  const { anchor, children, placement = 'bottom', isOpen: defaultIsOpen, cardProps, ...rest } = props

  const [menuProps, isOpen, open, close] = useModal({ placement, defaultIsOpen, ...rest })

  React.useImperativeHandle(ref, () => ({
    close,
    open
  }))

  if (!isOpen) return null

  return (
    <ModalContent>
      <Box sx={{
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: 99999
      }}>
        <Box sx={{
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          position: 'absolute',
          overflowY: 'scroll'
        }}>
        <div {...menuProps}>
          {isOpen && children}
        </div>
        </Box>
      </Box>
    </ModalContent>
  )
})

export default Modal