import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'

// Runic
import useDirector from 'runic/hooks/useDirector'

// RunicAura
import { useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'


const RncAppUnit = ({
  domain,
  item,
  profileName = 'Default'
}) => {
  const director = useDirector()
  const unit = useSelector(state => state.runicAura.units[item.unitName])
  useRncActivate('unit', unit)

  const profile = unit.profiles[profileName]

  const IndexComponent = director.components[profile.indexPage.name]
  if (!IndexComponent) {
    console.warn('Index not found', profile.indexPage.name)
    return null
  }


  return (
    <Flex pl={70} pr={40} py={10}>
      <Box width={1}>
        <IndexComponent unit={unit} profile={profile} />
      </Box>
    </Flex>
  )
}

export default RncAppUnit
