import React from 'react'

// Vendor
import ScaleLoader from 'react-spinners/ScaleLoader'

// Reverb
import { styled, space, t, tt, tc, css, Box } from '../index'
import Icon from './Icon'

const shadowHeight = 3
const shadowHeightHover = 2


const BaseButton = styled.button`
    position: relative;

    font-family: ${tt('button.fontFamily', 'fontFamily')};
    font-size:  ${tt('button.fontSize', 'fontSizes.base')};
    font-weight:  ${tt('button.fontWeight', 'fontWeight.regular')};

    text-align: center;

    cursor: pointer;

    display: inline-block;

    border: none;
    background: none;
    outline: none;

    ${space}

    transition: all 0.2s ease;

    margin-bottom: 3px;

    background-color: ${tt('button.backgroundColor', 'colors.primary')};
    color: ${tt('button.color', 'colors.primary9')};

    border-radius: ${tt('button.borderRadius', 'radii.2')};

    transform: translateY(0px);

    box-shadow: 0 ${shadowHeight}px ${tt('button.shadowColor', 'colors.primary3')};

    &:hover {
      box-shadow: 0 ${shadowHeightHover}px ${tt('button.shadowColor', 'colors.primary3')};
      transform: translateY(${shadowHeight - shadowHeightHover}px);
    }

    &:active {
      box-shadow: 0 0px ${tt('button.shadowColor', 'colors.primary3')};
      transform: translateY(${shadowHeight}px);
    }

    ${p => p.variant == 'border' && css({
  'backgroundColor': 'light',
  'borderColor': 'light',
  'borderWidth': '2px',
  'borderStyle': 'solid',
  'color': 'primary',
  'boxShadow': `0 ${shadowHeight}px ${tt('colors.light3')(p)}`,
  '&:hover': {
    'boxShadow': `0 ${shadowHeightHover}px ${tt('colors.light3')(p)}`
  },
  '&:active': {
    'boxShadow': `0 0px ${tt('colors.light3')(p)}`
  }
})(p)}
  `

const Button = React.forwardRef((props, ref) => {
  const { fullWidth, label, working, variant, submit, onClick, sx } = props

  const content = working ? (
    <ScaleLoader
      loading={true}
      css={{ paddingTop: 3, marginBottom: -3 }}
      height={10}
      width={2}
      color={'#FFF'} />
  ) : label

  const finalSx = {
    py: 3,
    width: fullWidth ? '100%' : 'auto',
    ...sx
  }

  return (
    <Box ref={ref} as={BaseButton} disabled={working} variant={variant} type={submit ? 'submit' : 'button'} onClick={onClick} sx={finalSx}>
      {content}
    </Box>
  )
})

const ButtonIconBase = React.forwardRef((props, ref) =>
  <Box
    ref={ref}
    as='button'
    type='button'
    {...props}
    __css={{
      p: 1,
      border: 'none',
      borderRadius: 2,
      background: 'none',
      cursor: 'pointer',
    }}
    variants={{
      flat: {
        color: 'primary',
        background: 'none',
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'transparent',
        transition: 'all 0.2s ease',
        '&:hover': {
          borderColor: 'primary',
          color: 'light',
          bg: 'primary',
        },
        '&:focus': {
          borderColor: 'primary3',
          outline: 'none',
        }
      },
      // secondary: {
      //   color: 'white',
      //   bg: 'secondary',
      // },
    }}
  />
)

export const ButtonIcon = React.forwardRef(({
  iconName,
  variant,
  color,
  ...rest
}, ref) => {

  return (
    <ButtonIconBase variant={variant} ref={ref} {...rest}>
      <Icon name={iconName} width={20} height={20} color={color} />
    </ButtonIconBase>
  )
})

export default Button