import React from 'react'

// Reverb
import Text from 'volcano/components/Text'

// RncUi
import { SidebarGroupWrapper, SidebarGroupHeader, RncMenuItem } from 'runic-aura/apps/core/components/RunicSidebar'
import useRncDomainMenu from '../hooks/useRncDomainMenu'


export const RncExtendedMenu = props => {
  const { activeDomain, units, activeUnit, uiConfig, basePath } = props

  return activeDomain.groups.map((group, dx) => {
    return (
      <SidebarGroupWrapper key={dx}>
        {activeDomain.groups.length != 1 ? <SidebarGroupHeader><Text textCase='upper' id={group.name} /></SidebarGroupHeader> : null}
        {group.items.map((menuItem, dx) => <RncMenuItem unit={units[menuItem.unitName]} menuItem={menuItem} activeUnit={activeUnit} uiConfig={uiConfig} basePath={basePath} key={dx} />)}
      </SidebarGroupWrapper>
    )
  })
}

export const RncQuickMenu = props => {
  const { activeDomain, units, activeUnit, uiConfig, basePath } = props
  return activeDomain.quickMenu.map((menuItem, dx) => <RncMenuItem unit={units[menuItem.unitName]} menuItem={menuItem} activeUnit={activeUnit} uiConfig={uiConfig} basePath={basePath} key={dx} />)
}

const RncDomainMenu = ({
  uiConfig,
  activeZone,
  activeDomain
}) => {
  const menuProps = useRncDomainMenu(
    uiConfig,
    activeZone,
    activeDomain
  )
  console.log('--', menuProps)

  return uiConfig.extended ? <RncExtendedMenu {...menuProps} /> : <RncQuickMenu {...menuProps} />
}

export default RncDomainMenu