import { hot } from 'react-hot-loader/root'
import React from 'react'

// Riva
import RunicApp from 'runic-aura/RunicApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'
import globalCss from './theme/globalCss'

import tenantApp from 'elements/rc_app/tenant'

const apps = [
  tenantApp,
]


const App = () => (
  <RunicApp
    storeCreator={storeCreator}
    config={{
      isMultiTenant: true,
      runicPlatform: 'RUNIC_MAIN'
    }}
    theme={theme}
    globalCss={globalCss}
    apps={apps}/>
)

export default hot(App)
