import React from 'react'

// Vendor
import dotProp from 'dot-prop'
import isObject from 'lodash/isObject'

// Volcano
import { Flex, Box } from 'volcano'

// Runic
import { Routes, Route, useParams, useSearchParams } from 'runic-aura/router'
import DynamicComponent from 'runic-aura/components/DynamicComponent'


const RunicTabs = ({
  tabs,
  ctx
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTabKey = searchParams.get('tabId')
  const activeTab = activeTabKey ? tabs.find(x => x.codeName === activeTabKey) : tabs[0]
  console.log(tabs, ctx, searchParams)
  console.log(activeTab)

  return (
    <Box>
      <Flex mx={-2} mb={3}>
        {tabs.map((tab, dx) => {
          const isActive = activeTab.codeName === tab.codeName
          return (
            <Box key={dx} as='a' onClick={() => setSearchParams({tabId: tab.codeName})} sx={{
              mx: 2,
              display: 'inlineBlock',
              borderBottom: isActive ? '4px solid #FFF' : '2px solid #FFF',
              borderBottomColor: 'primary',
              textAlign: 'center',
              py: 2,
              px: 3,
            }}>
              {tab.name}
            </Box>
          )
        })}
      </Flex>

      <Box>
        <RunicTab tab={activeTab} ctx={ctx}/>
      </Box>
    </Box>
  )
}

const createProps = (props, vars) => {
  const result = {}
  Object.keys(props).forEach(key => {
    let val = props[key]
    let newVal = val
    if (typeof val === 'string') {
      if (val.includes('@@r:')) {
        const valKey = val.replace('@@r:', '')
        newVal = dotProp.get(vars, valKey)
        console.log(valKey, val, newVal)
      }
    }

    if (isObject(val)) {
      newVal = createProps(val, vars)
    }

    result[key] = newVal
  })

  return result
}

const RunicTab = ({
  tab,
  ctx
}) => {
  const props =  createProps(tab.props, {ctx})

  return (
    <DynamicComponent componentName={tab.component} ctx={ctx} {...props}/>
  )
}

export default RunicTabs