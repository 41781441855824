import React from 'react'

// Vendor
import { useNavigate } from "runic-aura/router";

// Runic
import useAppState from 'runic/hooks/useAppState'

// Volcano
import { getInitials } from 'volcano/util'
import { styled, t, tt, tc } from 'volcano'

import { Menu, MenuItem } from 'volcano/components/Menu'

// Reverb - Component
// import { Menu, MenuList, MenuItem } from 'reverb/components/menu'


const UserMarkWrapper = styled.a`
  cursor: pointer;
  padding-bottom: 5px;
`;

const TextIcon = styled.div`
  background: ${tc('light')};
  border-radius: ${t('radii.1')};
  height: 23px;
  width: 30px;

  font-size: ${t('fontSizes.s')};
  text-align: center;

  padding-top: 8px;

  display: block;
  float: left;

  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
`

const UserIcon = React.forwardRef(({name, ...rest}, ref) => <TextIcon ref={ref} {...rest}>{getInitials(name)}</TextIcon>)

const RncUserMark = props => {
  const navigate = useNavigate()
  const {user} = useAppState()
  if (!user) return false
  return (
    <UserMarkWrapper>
      <Menu anchor={<UserIcon name={user.name}/>} placement='bottom-start'>
        <MenuItem onClick={() => navigate('/auth/logout')}>Çıkış</MenuItem>
      </Menu>
    </UserMarkWrapper>
  )
}

export default RncUserMark
