import React from 'react'

// Vendor
import {BrowserRouter as Router} from 'react-router-dom'

// Volcano
import Volcano from 'volcano'

// Runic
import RunicBaseApp from './RunicBaseApp'

import uiTreeApp from 'runic-aura/apps/uitree'
import modelApp from 'runic-aura/apps/model'
import standardDomainApp from 'runic-aura/apps/domain/standard-domain'

import RunicAura from 'runic-aura/systems/runic-aura'

import RunicIndex from 'runic-aura/apps/core/pages/RunicIndex'

const extraApps = [
  modelApp,
  standardDomainApp,
  uiTreeApp,
  // tenantApp,
  // modelDomainApp
]

const extraSystems = [
  RunicAura,
]

const RunicApp = ({
  theme,
  globalCss,
  uiConfig,
  apps = [],
  systems = [],
  storeCreator,
  config,
  rConfig,
  rncuiConfig,
  homeComponent
}) => {
  return (
    <RunicBaseApp apps={[...extraApps, ...apps]} systems={[...extraSystems, ...systems]} storeCreator={storeCreator} rConfig={rConfig}>
      <Router>
        <Volcano theme={theme} globalCss={globalCss}>
          <RunicIndex uiConfig={uiConfig || rncuiConfig} runicConfig={config} homeComponent={homeComponent} />
        </Volcano>
      </Router>
    </RunicBaseApp>
  )
}

export default RunicApp